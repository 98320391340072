<template>
  <div class="home-container">
    <div class="mobile__thought-nav-controller">
      <thought-nav-controller/>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Layout Editor</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <LayoutController />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="split-layout-comp">
      <Layout :key="componentKey" :resize="resize" :edit="edit" :splits="tree">
        <Pane
          v-for="layoutKey in layoutKeys"
          :key="layoutKey"
          class="split-layout-custom-style"
        >
          <div class="tab-top nav-box nav-box--pd-65">
            <ul class="nav nav-tabs nav-overflow">
              <li
                v-for="(value, key) in layout[layoutKey]"
                :key="key"
                class="nav-item"
              >
                <div
                  class="nav-link d-flex align-items-center widget-tab"
                  :class="layout_selection[layoutKey] === key ? 'active' : ''"
                  @click="updateLayout(layoutKey, key)"
                  @click.middle="removeTabFromLayout(layoutKey, key)"
                  :title="getShortcut(findTabTitle(value), findTabTitle(value))"
                >
                  <i
                    class="custom-help-css box-icon widget-tab__button widget-tab__button--front bx bx-help-circle"
                    :value="value"
                    :title="getShortcut('Help', 'Help')"
                    @click="help(value)"
                  ></i>
                  <div class="tab-title">
                    {{ findTabName(value) }}
                  </div>
                  <i
                    class="custom-box-css box-x box-icon widget-tab__button widget-tab__button--back bx bx-x"
                    title="Close"
                    @click.stop="removeTabFromLayout(layoutKey, key)"
                  ></i>
                </div>
              </li>
              <li class="nav-item">
                <div class="dropdown nav-icon">
                  <component-dropdown-vue :layoutKey="layoutKey" :addToWidget="addTabToLayout"/>
                  <label class="radio_for_active">
                    <input
                      type="radio"
                      v-model="active_key"
                      name="activeComponent"
                      :value="layoutKey"
                    />
                   <i
                      class="
                        active_comp_class
                        box-icon
                        bx bx-radio-circle-marked
                      "
                      :class="`
                        ${
                          layoutKey === active_key
                            ? 'icon-active icon-active--highlight'
                            : 'icon-inactive'
                        }
                      `"
                    ></i>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div class="tab-full">
            <component
              :is="layout[layoutKey][layout_selection[layoutKey]]"
            ></component>
          </div>
        </Pane>
      </Layout>
    </div>
  </div>
</template>
<script>
import { Layout, Pane } from 'vue-split-layout';
import ComponentDropdownVue from '@/common/components/ComponentDropdown.vue';
import ThoughtNavController from '@/common/components/ThoughtNavController.vue';
import {
  coreComponents,
  coreComponentOptions,
} from '../config/componentLoader';

export default {
  name: 'MobileView',

  metaInfo: {
    title: 'Mobile View',
  },

  components: {
    Layout,
    Pane,
    ComponentDropdownVue,
    ThoughtNavController,
    ...coreComponents,
  },
  data() {
    return {
      active_key: '_a',
      componentKey: 0,
      tree: {
        dir: 'vertical', // Left | Right
        // Other split
        first: 0,
        split: '100%',
      },
      layoutKeys: ['_a', '_b'],
      layout: {
        _a: ['QuestLibrary'],
        _b: ['ThoughtMap'],
      },
      layout_selection: {
        _a: 0,
        _b: 0,
      },
    };
  },

  watch: {
    active_key(val) {
      this.$store.dispatch('setActiveLayoutKey', val);
    },
  },
  methods: {
    findTabName(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.options?.title;
    },
    findTabTitle(value) {
      const tab = this.componentsOptions.find((item) => item.name === value);
      return tab?.name;
    },
    help(value) {
      this.$store
        .dispatch('fetchWidgetName', this.convertToKebabCase(value))
        .then(() => {
          this.$store.dispatch('activateLayout', {
            layoutKey: '_e',
            componentName: 'Help',
          });
        });
    },
    addPanel() {
      const panelId = `_${String.fromCharCode(
        this.layoutKeys[this.layoutKeys.length - 1].charCodeAt(1) + 1,
      )}`;
      const newPanelId = String(panelId);

      this.layoutKeys = [...this.layoutKeys, newPanelId];

      const newLayout = {};
      const newLayoutSelection = {};
      Object.keys(this.layout).forEach((key) => {
        newLayout[key] = this.layout[key];
      });
      newLayout[newPanelId] = [];

      this.layout = newLayout;
      Object.keys(this.layout_selection).forEach((key) => {
        newLayoutSelection[key] = this.layout_selection[key];
      });
      newLayoutSelection[newPanelId] = 0;
      this.layout_selection = newLayoutSelection;
      const customTree = {
        dir: 'horizontal',
        first: this.tree,
        second: this.layoutKeys.length - 1,
        split: '75%',
      };

      this.tree = customTree;
      this.componentKey += 1;
      // this.customTree = {
      //   dir: horizontal,

      // };
    },
    updateLayout(key, index) {
      this.layout_selection[key] = index;
      this.updateLayoutToState();
    },
    removeTabFromLayout(key, index) {
      this.layout[key].splice(index, 1);
      this.updateLayoutToState();
    },
    addTabToLayout(key, component) {
      this.layout[key].push(component);
      this.updateLayoutToState();
    },
    // merge this.layout with this.$store.state.layout.layout_tabs
    updateLayoutBasedOnState() {
      this.layout = this.$store.state.layouts[this.$store.state.layouts.view].layout_tabs;
      this.layout_selection = this.$store.state.layouts[this.$store.state.layouts.view].layout_tab_selection;
    },
    updateLayoutToState() {
      this.$store.dispatch('updateLayoutSettings', {
        layout: this.layout,
        layout_selection: this.layout_selection,
      });
    },
    getShortcut(label, _key) {
      return this.$fetchShortcut(label, _key);
    },
  },

  computed: {
    randomNumer() {
      return Math.random();
    },
    componentsOptions() {
      return coreComponentOptions.sort((a, b) => {
        if (a.options.title < b.options.title) return -1;
        return a.options.title > b.options.title ? 1 : 0;
      });
    },
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        return true;
      }

      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
      ) {
        return true;
      }
      return false;
    },

    showExploreDescription() {
      return this.$store.state.explore.extract;
    },
    edit() {
      return this.$store.state.layouts.edit;
    },
    resize() {
      return this.$store.state.layouts.resize;
    },
  },
  mounted() {
    const thoughtId = this.$route.params.id;

    if (thoughtId) {
      this.$store.dispatch('getSavedTjState', thoughtId);
    }
    // listen root events
    this.$root.$on('addPanel', (payload) => {
      if (payload === 'Home') {
        this.addPanel();
      }
    });
    this.updateLayoutBasedOnState();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (
        mutation.type === 'SET_LAYOUT_SETTINGS'
        || mutation.type === 'SET_SAVED_TJ_STATE'
      ) {
        this.updateLayoutBasedOnState();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$root.$off('addPanel', () => {
      this.addPanel();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/abstracts/variables";

// #split-layout-comp {
//   height: 105vh;
//   z-index: -5;
//   padding: 1em;
// }
.radio_for_active {
  // position: absolute;
  // top: -10px;
  // right: 0;
  // z-index: 999999;
  [type="radio"] {
    // position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .active_comp_class {
    cursor: pointer;
  }
}
.split-layout-custom-style {
  border-radius: 5px;
  overflow-x: hidden !important;
}

.nav-tabs {
  display: flex;
  align-items: center;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 4px;
  gap: 0.5em;

  &:focus,
  &:hover {
    background: #f2f2f2;
    cursor: pointer;
  }
}

.nav-link {
  color: rgba(0, 0, 0, 0.4);
}

.nav-tabs {
  border-bottom: 0;
}
.nav-item {
  display: flex;
  align-items: flex-end;
}

.tab-top {
  margin-bottom: 1em;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white;
  z-index: 2;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.tab-full {
  padding: 10px;
  height: 100%;
  position: relative;
}

.tab-content {
  display: flex;
  align-items: center;
}

.tab-active {
  .tab-content {
    color: $edvantaBlue;

    .tab-title {
      padding-bottom: 0.3em;
      border-bottom: 3px solid $edvantaYellow;
    }
  }
}

.custom-box-css {
  border-radius: 50%;
  transition: all ease-in-out 200ms;

  &:hover {
    cursor: pointer;
    background: rgba(154, 160, 166, 0.157);
  }
}

.box-x {
  padding: 2px;
}

.component-menu {
  max-height: 12em;
  overflow: auto;
}

.mobile {
  &__thought-nav-controller {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
